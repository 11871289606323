<template>
    <div class="Fishbonediagram">
        <div class="Fishb_div">
            <div class="fish_div">
                <div class="fish_img">
                    <img src="./../../../assets/img/fish.png" alt="">
                    <div class="_img_duiv _img_one">
                        <p class="_img_p">能力分类①</p>
                        <div class="_img_inpu">
                            <el-input
                                placeholder="请输入内容"
                                v-model="input">
                            </el-input>
                        </div>
                    </div>
                    <div class="_img_duiv _img_two">
                        <p class="_img_p">能力分类②</p>
                        <div class="_img_inpu">
                            <el-input
                                placeholder="请输入内容"
                                v-model="input">
                            </el-input>
                        </div>
                    </div>
                    <div class="_img_duiv _img_three">
                        <p class="_img_ps">能力分类③</p>
                        <div class="_img_inpus">
                            <el-input
                                placeholder="请输入内容"
                                v-model="input">
                            </el-input>
                        </div>
                    </div>
                    <div class="_img_duiv _img_four">
                        <p class="_img_ps">能力分类④</p>
                        <div class="_img_inpus">
                            <el-input
                                placeholder="请输入内容"
                                v-model="input">
                            </el-input>
                        </div>
                    </div>
                </div>
                <hr class="fish_hrs">
                <div class="fish_right">
                    <hr class="fish_hr">
                    <div class="fish_right_o">
                        <p class="fish_p">核心关键能力</p>
                        <div class="_right_div">
                            <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model="textarea">
                            </el-input>
                        </div>
                    </div>
                    <hr class="fish_hr">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            textarea:'',
            input:'',
        }
    },
    methods: {
        
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../../assets/css/student/B/Fishbonediagram.css';
</style>
<style>
._right_div .el-textarea{
    width: 100%;
    height: 100%;
}
._right_div .el-textarea__inner{
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    resize: none;
    padding: 0px 10px 5px;
    font-size: 12px;
}
._img_inpu .el-input,._img_inpus .el-input{
    height: 100%;
}
._img_inpu .el-input__inner,._img_inpus .el-input__inner{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    background: transparent;
    font-size: 12px;
    padding: 0;
    display: flex;
    align-items: center;
}
</style>